import axios from 'axios';
import AuthService from 'services/Auth';

class Api {
  constructor(resource = '') {
    const apiUrl = process.env.REACT_APP_API_URL;
    this.url = `${apiUrl}/${resource}`;
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  getExcel = (params) => {
    return axios({
      url: `${this.url.slice(0, -1)}.xlsx`,
      method: 'get',
      data: params.data,
      params: params.params,
      responseType: 'blob', // important
      headers: {
        'Content-Type': 'application/json',
        ...(params?.headers || {}),
        'auth-token': AuthService.getToken(),
        'Lombardy': 'true',
      },
    });
  };

  getPDFReceipt = (shop_id, prod_id) => {
    return axios({
      url: `${this.url}/admin/shops/${shop_id}/orders/${prod_id}/label`,
      method: 'get',
      responseType: 'blob',
      headers: {
        'auth-token': AuthService.getToken(),
        'Lombardy': 'true',
      },
    }).then((response) => {
      return response.data.text().then((text) => {
        try {
          const jsonResponse = JSON.parse(text);
          console.log('Received JSON response:', jsonResponse.qr);
          return jsonResponse.qr;
        } catch (e) {
          return window.URL.createObjectURL(new Blob([response.data]));
        }
      });
    });
  };

  list = (params) => {
    return this.buildQuery('get', params);
  };

  get = (id, params = {}) => {
    return this.buildQuery('get', params, id);
  };

  create = (params) => {
    return this.buildQuery('post', params);
  };

  put = (id, params) => {
    return this.buildQuery('put', params, id);
  };

  destroy = (id) => {
    return this.buildQuery('delete', {}, id);
  };

  buildQuery = (method, params = { props: {} }, prefix) => {
    const props = params?.props || {};
    const authToken = AuthService.getToken();

    return axios({
      url: this.getFullUrl(prefix),
      method,
      timeout: 10000 * 6,
      data: params.data,
      params: params.params,
      responseType: params.responseType,
      ...props,
      headers: {
        'Content-Type': 'application/json',
        ...(params?.headers || {}),
        'auth-token': authToken,
        'Lombardy': 'true',
      },
    });
  };

  getFullUrl = (prefix) => {
    return `${this.url}${prefix ? `${prefix}` : ''}`;
  };
}

export default Api;
