import Api from 'libs/api';
import AuthService from 'services/Auth';
import axios from 'axios';

class OrdersApi extends Api {
  constructor() {
    super('admin/orders/');
  }

  createOrder = (params) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return axios({
      url: `${apiUrl}/admin/shops/${AuthService.getCurrentShop()}/orders`,
      method: 'post',
      data: params?.data,
      params: {},
      // responseType: 'post', // important
      headers: {
        'Content-Type': 'application/json',
        'auth-token': AuthService.getToken(),
        'Lombardy': 'true',
      },
    });
  };

  refundOrder = (id, params) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return axios({
      url: `${apiUrl}/admin/shops/${AuthService.getCurrentShop()}/orders/${id}/refund`,
      method: 'post',
      data: params?.data,
      params: {},
      // responseType: 'post', // important
      headers: {
        'Content-Type': 'application/json',
        'auth-token': AuthService.getToken(),
        'Lombardy': 'true',
      },
    });
  };

  waitingForRefund = (id, params) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return axios({
      url: `${apiUrl}/admin/shops/${AuthService.getCurrentShop()}/orders/${id}/waiting_for_products_refund`,
      method: 'post',
      data: params?.data,
      params: {},
      // responseType: 'post', // important
      headers: {
        'Content-Type': 'application/json',
        'auth-token': AuthService.getToken(),
        'Lombardy': 'true',
      },
    });
  };
}

export default OrdersApi;
