import Api from 'libs/api';
import axios from 'axios';
import AuthService from '../../services/Auth';

class ProductVariantsApi extends Api {
  constructor() {
    super('admin/product_variants/');
  }

  bulkDestroy(ids) {
    return this.buildQuery(
      'post',
      {
        data: {
          ids,
        },
      },
      'bulk_destroy',
    );
  }

  uploadProducts = (shopId, formData) => {
    return axios({
      url: `${this.apiUrl}/admin/shops/${shopId}/product_imports`,
      method: 'post',
      timeout: 10000 * 6,
      data: formData,
      params: {},
      headers: {
        'Content-Type': 'application/json',
        'content-type':
          'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        Accept:
          'text/xml,application/xml,application/xhtml+xml,text/html;q=0.9,text/plain;q=0.8,image/png,*/*;q=0.5',
        'auth-token': AuthService.getToken(),
        'Lombardy': 'true',
      },
    });
  };

  getUploadStatus = (shopId, id) => {
    return axios({
      url: `${this.apiUrl}/admin/shops/${shopId}/product_imports/${id}`,
      method: 'get',
      timeout: 10000 * 6,
      data: {},
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Accept:
          'text/xml,application/xml,application/xhtml+xml,text/html;q=0.9,text/plain;q=0.8,image/png,*/*;q=0.5',
        'auth-token': AuthService.getToken(),
        'Lombardy': 'true',
      },
    });
  };

  updateImage(productId, data) {
    return this.buildQuery(
      'put',
      {
        headers: {
          'content-type':
            'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
          Accept:
            'text/xml,application/xml,application/xhtml+xml,text/html;q=0.9,text/plain;q=0.8,image/png,*/*;q=0.5',
        },
        data,
      },
      `${productId}`,
    );
  }

  updateStatus(data) {
    return this.buildQuery(
      'put',
      {
        data,
      },
      'update_status',
    );
  }
}

export default ProductVariantsApi;
